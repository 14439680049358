<template>
    <div id="mainContainer" v-if="iodReport && display">
        <div v-if="debug" id="debugForm">
            <div class="formItem">
                <div>Debugging Utils</div>
            </div>

            <div class="formItem">
                <label for="isToric">Is Toric</label>
                <input id="isToric" type="checkbox" v-model="iodReport.eye.isToric" />
            </div>

            <div class="formItem">
                <label for="firstName">First Name</label>
                <input id="firstName" type="input" v-model="iodReport.firstName" />
            </div>

            <div class="formItem">
                <label for="lastName">Last Name</label>
                <input id="lastName" type="input" v-model="iodReport.lastName" />
            </div>
        </div>

        <div id="reportContainer" ref="reportContainer">
            <IodReport
                id="iod-report"
                embedded
                :lensOrderId="lensOrderId"
                :mendezRing="mendezRing"
                :eyeOutline="eyeOutline"
                @error="onCompError"
                :lensOverride_SerialNum="lensOverride_SerialNum"
                @updated="resizeReportContainer"
            />
        </div>

        <div id="controlsContainer">
            <div class="top-controls">
                <div id="reportTitle" v-if="iodReport.eye.isToric">
                    {{ t('iodreport_Title') }}
                </div>
                <div v-else id="reportTitle">{{ t('iodreport_SphericImpDiagram') }}</div>

                <div id="sliderContainer">
                    <b-form-checkbox
                        switch
                        class="custom-checkbox-label no_selection"
                        v-model="mendezRing"
                        :disabled="compError"
                    >
                        {{ t('iodreport_ShowMendez') }}
                    </b-form-checkbox>

                    <b-form-checkbox
                        switch
                        class="custom-checkbox-label no_selection"
                        v-model="eyeOutline"
                        :disabled="compError"
                    >
                        {{ t('iodreport_ShowEyeOutline') }}
                    </b-form-checkbox>
                </div>
            </div>

            <div class="bottom-controls">
                <b-button
                    id="fullscreen-button"
                    block
                    @click="printClick(true)"
                    :disabled="compError"
                >
                    {{ t('iodreportpopup_Fullscreen') }}
                    <b-icon class="pointer ml-2" icon="fullscreen"></b-icon>
                </b-button>
                <b-button id="print-button" block @click="printClick(false)" :disabled="compError">
                    {{ t('print') }}
                    <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                </b-button>
                <b-button id="cancel-button" block @click="cancelKeyClicked">
                    {{ t('cancel') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import IodReport from '@//views/iodreport/IodReport.vue';
import {mapGetters} from 'vuex';
import {definedAndNotBlankObj} from '@/utilities/object';

export default {
    components: {
        IodReport,
    },
    data() {
        return {
            compError: false,
            mendezRing: true,
            eyeOutline: true,
            iodReport: null,
            iodReportDebug: {
                firstName: 'Bartholomewzz',
                lastName: 'Featherstonethrowzz',
                eye: {
                    isToric: true,
                },
            },
        };
    },
    props: {
        debug: {
            type: Boolean,
            default: false,
        },

        display: {
            type: Boolean,
            default: false,
        },

        lensOrderId: {
            type: String,
            required: true,
        },

        cancelClicked: {
            default: false,
        },

        lensOverride_SerialNum: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        definedAndNotBlankObj,

        printClick(dLPrintDialog = false) {
            let query = {
                eyeOutline: this.eyeOutline,
                mendezRing: this.mendezRing,
            };

            if (this.lensOverride_SerialNum)
                query.lensOverride_SerialNum = this.lensOverride_SerialNum;

            if (this.debug) query.debug = true;
            if (dLPrintDialog) query.dontLaunchPrintDialog = true;

            const routeData = this.$router.resolve({
                name: 'IodReport',
                params: {
                    lensOrderId: this.lensOrderId,
                },
                query: query,
            });

            window.open(routeData.href, '_blank');
        },

        cancelKeyClicked() {
            this.$emit('update:cancelClicked', true);
            this.showIodReportUI = false;
        },

        /**
         * The report is resized using `transform: scale()`, which doesn't update the
         * document flow after resizing. This uses margin to account for the difference in
         * size after scaling the report.
         */
        resizeReportContainer() {
            const container = this.$refs.reportContainer;
            if (container) {
                // NB: getBoundingClientRect returns the size after scaling, offsetWidth/offsetHeight
                //     return the size before scaling.
                const scaleX = container.getBoundingClientRect().width / container.offsetWidth;
                const scaleY = container.getBoundingClientRect().height / container.offsetHeight;

                const offsetX = container.offsetWidth * (scaleX - 1);
                const offsetY = container.offsetHeight * (scaleY - 1);

                container.style.marginRight = `${offsetX}px`;
                container.style.marginBottom = `${offsetY}px`;
            }
        },

        onCompError(value) {
            this.compError = value;
        },

        async loadReport() {
            let fetchIodReportObj = {};

            fetchIodReportObj.generatingIod = false;

            if (this.lensOrderId != undefined) {
                //We are running embedded so use this value
                fetchIodReportObj.lensOrderId = this.lensOrderId;
            }

            if (
                fetchIodReportObj.lensOrderId == undefined ||
                fetchIodReportObj.lensOrderId.length == 0
            ) {
                //We have a problem so alert
                //Todo - Does this go to toast?
                alert('Error: The lensOrderId is empty');
            } else if (fetchIodReportObj.lensOrderId == 'zzz') {
                this.iodReport = this.iodReportDebug;
            } else if (this.lensOverride_SerialNum) {
                fetchIodReportObj.serialNum = this.lensOverride_SerialNum;
                await this.blockingRequest(
                    'iodreport/fetchIodReportWithOverride',
                    fetchIodReportObj
                );
                this.iodReport = this.iodReportRemote;
            } else {
                await this.blockingRequest('iodreport/fetchIodReport', fetchIodReportObj);
                this.iodReport = this.iodReportRemote;
            }
        },
    },

    computed: {
        ...mapGetters({
            iodReportRemote: 'iodreport/iodReport',
        }),
    },

    watch: {
        display: async function (newVal, oldVal) {
            if (newVal) {
                await this.loadReport();
            }
        },
    },

    created() {
        window.addEventListener('resize', this.resizeReportContainer);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeReportContainer);
    },
};
</script>
<style lang="scss">
@import '@/assets/css/_screensize.scss';

#mainContainer {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    column-gap: 2rem;

    @include xl-max {
        flex-direction: column-reverse;
        align-items: stretch;
    }
}

#reportContainer {
    flex: 1;
    transform-origin: top left;
    transform: scale(0.45);

    @include xl-max {
        transform: scale(0.5);
    }

    @include lg-max {
        transform: scale(0.305);
    }

    @include sm-max {
        max-width: calc(100% * (1 / 0.305));
        overflow-x: auto;
    }
}

#controlsContainer {
    flex: 1;
    flex-grow: 1;
    font-size: 2.14rem;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    row-gap: 1rem;

    @include xl-max {
        flex-direction: row;
        padding-top: 0;
        .top-controls,
        .bottom-controls {
            flex: 1;
        }
    }

    @include sm-max {
        flex-direction: column;
    }
}

#reportTitle {
    color: #10a8ba;
    font-weight: bold;
    font-size: 1.14rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
}

#patientName {
    font-size: 1.4rem;
    padding-top: 13px;
    font-weight: 600;
}

#sliderContainer {
    color: #91979d;
}

.bottom-controls > button {
    padding: 11px 0px;
    font-size: 0.857rem;
}

#print-button,
#fullscreen-button {
    background-color: #10a8ba;

    color: white;
    border: white;
}

#cancel-button {
    color: #91979d;
    border: 1px solid #91979d;
    border-radius: 3px;
}

#debugForm {
    position: absolute;
    z-index: 10;
    top: 0px;
    background-color: aliceblue;
    left: 950px;
    width: 351px;
}

/*Todo - Need to fix the styles to remove this selector*/
.bottom-controls button:disabled {
    opacity: 0.6;
}
</style>
