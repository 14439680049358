<!-- This is the Patient Detail page -->
<template>
    <b-container fluid class="patient-detail d-flex flex-column" v-if="loaded">
        <PatientDetailsHeaderBar />
        <b-row class="pt-2">
            <b-col :xl="hasPreopDataPermission ? 8 : 12">
                <b-row>
                    <b-col class="py-2">
                        <PersonalCard
                            :key="initialState.patientId"
                            :patientState="initialState"
                            :startInEditMode="startInEditMode"
                            :isDoctorValid="currentPatient.doctorActive"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <!-- The Lens Surgery Info/Reserve/Orders cards -->
                    <b-col class="py-2">
                        <b-card class="tabs-card tab-container py-3">
                            <b-tabs
                                pills
                                horizontal
                                lazy
                                class="lens-tabs"
                                nav-class="d-block d-sm-flex text-center"
                            >
                                <b-tab
                                    :title="t('lensSurgeryInfo')"
                                    title-link-class="text-white text-caption"
                                    title-item-class="py-1"
                                    class="my-2"
                                >
                                    <LensSelection :key="initialState.patientId" />
                                </b-tab>
                                <b-tab
                                    :title="t('reserve')"
                                    title-link-class="text-white text-caption"
                                    title-item-class="py-1"
                                    class="my-2"
                                >
                                    <ReserveCard :key="initialState.patientId" />
                                </b-tab>
                                <b-tab
                                    v-if="hasOrderingPermission"
                                    :title="t('orders')"
                                    title-link-class="text-white text-caption"
                                    title-item-class="py-1"
                                    class="my-2"
                                >
                                    <OrdersCard :key="initialState.patientId" />
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="4" v-if="hasPreopDataPermission">
                <b-row class="py-2">
                    <b-col>
                        <b-card>
                            <PreOpDataAndCalc
                                :preOpDataSets="currentPatientPreOpData.preOpDataSets"
                                :patient="currentPatient"
                            />
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import PatientDetailsHeaderBar from './components/PatientDetailsHeaderBar.vue';
import LensSelection from './components/LensSelection.vue';
import PreOpDataAndCalc from './components/PreOpDataAndCalc.vue';
import PersonalCard from './components/PersonalCard.vue';
import ReserveCard from './components/ReserveCard.vue';
import OrdersCard from './components/OrdersCard.vue';
import {mapGetters} from 'vuex';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    components: {
        PatientDetailsHeaderBar,
        LensSelection,
        PreOpDataAndCalc,
        PersonalCard,
        ReserveCard,
        OrdersCard,
    },
    props: {
        startInEditMode: {
            default: false,
        },
    },
    data() {
        return {
            /* To prevent errors, Don't render the page until all the getters have finished loading data. */
            loaded: false,

            /* Create a copy of the patient's current data, which can be passed to the Personal card and edited, without altering the v-modeled values until any personal details are updated. */
            initialState: {},
            name: 'PatientDetails',
        };
    },

    watch: {
        /* Fetch the patient's data, using the patient ID from the router link. */
        '$route.params.patientId': {
            handler: async function (value) {
                if (!value) return;
                this.setSpinner(true);
                let requests = [];
                let patientId = value;
                if (this.$route.params.startInEditMode) {
                    this.startInEditMode = this.$route.params.startInEditMode;
                }

                /* Fetch the patient personal details. */
                requests.push(
                    this.$store
                        .dispatch('patient/fetchPatient', {
                            patientId: patientId,
                        })
                        .catch((err) => {
                            this.$store.commit('showAlert', {
                                response: err.response,
                                fallbackMsg: 'Failed to fetch patient',
                                seconds: 5,
                            });
                        })
                );

                //the following if is incorrect -- PPD access should not be used here
                //if (this.hasPatientPersonalDataPermission) {
                /* Fetch the patient's lenses data. */
                requests.push(
                    this.$store
                        .dispatch('patient/fetchPatientLenses', {
                            patientId: patientId,
                        })
                        .catch((err) => {
                            this.$store.commit('showAlert', {
                                response: err.response,
                                fallbackMsg: 'Failed to fetch patient lenses',
                                seconds: 5,
                            });
                        })
                );
                //}

                /* Fetch the doctor list. */
                requests.push(
                    this.$store
                        .dispatch('patient/fetchPatientSurgeryDateTimeData', {
                            patientId: patientId,
                        })
                        .catch((err) => {
                            this.$store.commit('showAlert', {
                                response: err.response,
                                fallbackMsg: 'Failed to fetch surgery dates',
                                seconds: 5,
                            });
                        })
                );
                if (this.hasPreopDataPermission) {
                    /* Fetch the patient's pre-op data and calculations. */
                    requests.push(
                        this.$store
                            .dispatch('patient/fetchPatientPreOpDataAndCalc', {
                                patientId: patientId,
                            })
                            .catch((err) => {
                                this.$store.commit('showAlert', {
                                    response: err.response,
                                    fallbackMsg:
                                        'Failed to fetch patient preop data ' + 'and calculations',
                                    seconds: 5,
                                });
                            })
                    );
                }

                /* Fetch the doctor list. */
                requests.push(
                    this.$store
                        .dispatch('doctors/fetchList', {
                            currentPage: 1,
                            perPage: 0, // fetch all the doctors
                        })
                        .catch((err) => {
                            this.$store.commit('showAlert', {
                                response: err.response,
                                fallbackMsg: 'Failed to fetch doctor data',
                                seconds: 5,
                            });
                        })
                );

                try {
                    await Promise.allSettled(requests);
                } finally {
                    // All patient data is loaded loaded
                    this.loaded = true;
                    this.setSpinner(false);
                    this.initialState = JSON.parse(JSON.stringify(this.currentPatient));
                }
            },
            deep: true,
            immediate: true,
        },
    },

    /* Set the initial state to a copy of the patient's current data. */
    mounted() {
        this.initialState = JSON.parse(JSON.stringify(this.currentPatient));
    },

    computed: {
        ...mapGetters({
            currentPatient: 'patient/currentPatient',
            currentPatientCalculations: 'patient/currentPatientCalculations',
            currentPatientPreOpData: 'patient/currentPatientPreOpData',
        }),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('user', ['currentUser']),
        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        hasPatientPersonalDataPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PERSONAL_DATA]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        hasPreopDataPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PREOP_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            });
        },
        tabs: function () {
            return [this.t('lensSurgeryInfo'), this.t('reserve'), this.t('orders')];
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
    },
};
</script>
